<template>
  <div class="hints-documentation">
    <ul class="list-unstyled">
      <li v-for="tag in tagInfo" :key="tag.tag" class="mb-2">
        <span class="tag-name font-weight-bold">&lt;{{ tag.tag }}&gt;</span>
        &nbsp; <span class="tag-description">{{ tag.descr }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TeiEditorHintDocumentation",
  props: {
    tagInfo: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      showInfo: false
    };
  }
};
</script>
